<template>
  <div style="transform: none!important;">
    <item-card :item="selectedItem" :restaurant="restaurant" @close="selectedItem = null" />
    <div>
      <div class="position-relative">
        <div class="restaurant-cover">
          <cld-image
            :publicId="restaurant.coverImage"
            style="max-height: 274px;object-fit: cover;height: 100%;width: 100%"
          >
            <cld-transformation height="400" width="600" crop="limit" />
          </cld-image>
        </div>
        <div class="d-none justify-content-center align-items-center restaurant-rounded-image">
          <cld-image class="img-fluid" width="76" height="76" :publicId="restaurant.imageName">
            <cld-transformation height="86" width="86" crop="limit" />
          </cld-image>
        </div>
      </div>
      <div class="font-circular text-dark restaurant-name">
        {{ restaurant.name }}
      </div>
      <div v-if="restaurant.address" class="text-dark font-circular font-medium-1 mb-2">
        {{ restaurant.address.printable }}
      </div>
    </div>
    <div v-if="menus && menus.length > 1 && selectedMenu" class="mb-1">
      <div class="text-dark font-circular font-medium-1 d-inline">
        {{ selectedMenu.name }}
      </div>
      <b-dropdown text="" variant="flat-dark" dropright class="custom-dropdown">
        <b-dropdown-item
          v-for="menu in menus"
          :key="menu.menuID"
          v-ripple.400="'rgba(30, 30, 30, 0.15)'"
          :active="menu.menuID === selectedMenu.menuID"
          @click="selectMenu(menu)"
        >
          <div class="">
            {{ menu.name }}
          </div>
          <div class="font-small-4">
            {{ menu.note }}
          </div>
        </b-dropdown-item>
        <!--      <b-dropdown-divider />-->
      </b-dropdown>
      <div>
        {{ selectedMenu.note }}
      </div>
    </div>
    <b-tabs
      v-if="categories"
      class="custom-tabs"
      nav-wrapper-class="sticky-top"
      active-tab-class="this-is-active-tab"
      content-class=""
      no-fade
      @activate-tab="tabSelected"
    >
      <b-tab
        v-for="(category, index) in categories"
        :id="'a' + category.id"
        :key="category.id"
        v-observe-visibility="{
          callback: isVisible => selectTab(isVisible, index),
          intersection: {
            threshold: 0.34,
          },
        }"
        class="pb-5"
        :title="category.name"
      >
        <h3 class="text-center mb-0">
          {{ category.name }}
        </h3>
        <items
          :items="category.items"
          :restaurant="restaurant"
          @selectItem="
            item => {
              selectItem(item, category, { name: restaurant.name, id: restaurant._id })
            }
          "
        />
      </b-tab>
    </b-tabs>

    <div
      v-if="!menus || !categories"
      class="d-flex justify-content-center flex-column align-items-center m-1"
    >
      <b-spinner style="width: 3rem; height: 3rem;" variant="dark" />
    </div>

    <div v-if="cartCount > 0" class="theme-dark floating-cart">
      <b-card
        class="text-white card-zero-padding"
        style="background: #222; font-weight: bolder; border-radius: 0;cursor: pointer"
      >
        <div
          class="d-flex justify-content-between align-items-center p-1"
          @click="$router.push('order-summary')"
        >
          <span>
            <feather-icon
              :badge="cartCount"
              class="text-white"
              badge-classes="badge-dark"
              icon="ShoppingCartIcon"
              size="21"
          /></span>
          <span>View Basket</span>
          <span>${{ totalAmount.toFixedNumber(2) }}</span>
        </div>
      </b-card>
    </div>

    <div v-if="activeOrders.length > 0" class="theme-dark floating-orders">
      <b-card
        class="text-white card-zero-padding"
        style="background: #22222277; border-radius: 0;cursor: pointer"
      >
        <div class="d-flex align-content-center px-2 p-1" @click="$router.push('orders')">
          <span class="mr-4">
            <img :src="mySVG" alt="x" height="40" />
          </span>
          <div class="d-inline-block">
            <div class="font-weight-bold font-medium-2">Active Orders</div>
            <div style="margin-top: 4px">
              View Orders
              <feather-icon class="text-white" icon="ChevronRightIcon" size="18" />
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<style>
body {
  scroll-behavior: smooth;
}
.floating-cart {
  position: fixed;
  bottom: 0;
  z-index: 99;
  right: 2%;
  left: 2%;
}
.floating-orders {
  position: fixed;
  bottom: 70px;
  z-index: 99;
  right: 2%;
  left: 2%;
}
.floating-cart .card,
.floating-orders .card {
  margin-bottom: 10px !important;
}
</style>
<script>
import { BCard, BDropdown, BDropdownItem, BSpinner, BTab, BTabs } from 'bootstrap-vue'
import ItemCard from '@/components/ItemCard.vue'
import Ripple from 'vue-ripple-directive'
import Items from '@/components/items.vue'
import { mapActions, mapState } from 'vuex'
// import Category from '@/components/category.vue'

export default {
  components: {
    // Category,
    Items,
    ItemCard,

    BTabs,
    BTab,
    BDropdown,
    // BDropdownDivider,
    BDropdownItem,
    BSpinner,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      mySVG: require('../assets/images/icons/Ripple-1s-200px.svg'),
      activeOrders: [],
      selectedTab: 0,
      isObserverving: true,
      selectedItem: null,
      showItemCard: false,
      categories: null,
      selectedMenu: {
        menuID: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        name: 'Wild All Day',
        note: 'Note for menu',
        state: 'live',
        dateCreated: '2021-07-06T05:17:27.327Z',
      },
      menus: null,
    }
  },
  computed: {
    ...mapState({
      restaurant: state => state.restaurant.restaurant,
      cart: state => state.cart.added,
      user: state => state.user.user,
      cartCount: state =>
        state.cart.added.reduce(
          (item, current) => ({ quantity: item.quantity + current.quantity }),
          { quantity: 0 }
        ).quantity,
    }),
    totalAmount() {
      let total = 0
      this.cart.forEach(i => {
        total += i.totalPrice
      })
      return total
    },
  },
  created() {
    this.getMenus()
    this.getRestaurant()
    // this.getCategories()
    this.getActiveOrders()
  },
  methods: {
    ...mapActions({
      updateRestaurant: 'restaurant/updateRestaurant',
    }),
    toElementTop(element) {
      if (!element) return
      const elementData = element.getBoundingClientRect().top + window.scrollY - 115

      window.scroll({
        top: elementData,
        behavior: 'smooth',
      })
    },
    toElementCenter(element) {
      if (!element) return
      const listContainer = element.parentNode.parentElement
      const listItemCenterPosition =
        element.parentNode.offsetLeft -
        (listContainer.getBoundingClientRect().width -
          element.parentNode.getBoundingClientRect().width) /
          2
      element.parentNode.parentElement.scrollTo(listItemCenterPosition, 0)
    },
    // eslint-disable-next-line no-unused-vars
    tabSelected(newTabIndex, prevTabIndex, bvEvent) {
      this.isObserverving = false
      setTimeout(() => {
        this.isObserverving = true
      }, 1500)
      // this.selectedTab = newTabIndex
      const element = document.querySelector(`#a${this.categories[newTabIndex].id}`)
      this.toElementTop(element)
      this.selectTitle(newTabIndex)
      // bvEvent.preventDefault()
    },
    selectTab(isShown, tabIndex) {
      if (isShown && this.isObserverving) {
        this.selectedTab = tabIndex
        this.selectTitle(tabIndex, true)
      }
    },
    selectTitle(index) {
      const tabWrapper = document.querySelector('.custom-tabs')
      const tabs = tabWrapper.querySelectorAll('li.nav-item a.nav-link')
      // console.log(index, tabs)
      if (tabs.length > 0) {
        tabs.forEach(activeTab => activeTab.classList.remove('active'))
        tabs[index].classList.add('active')
        this.toElementCenter(tabs[index])
      }
    },
    selectItem(item, category) {
      this.selectedItem = {
        ...item,
        categoryID: category.id,
        categoryName: category.name,
        restaurant: {
          // eslint-disable-next-line no-underscore-dangle
          _id: this.restaurant._id || 'no id found',
          name: this.restaurant.name,
        },
      }
    },
    selectMenu(menu = {}) {
      this.selectedMenu = menu
      this.categories = menu.categories || []
    },
    async getRestaurant() {
      const restaurant = (
        await this.$http.get(`/restaurants/${this.$route.query.id}`, {
          withCredentials: true,
        })
      ).data
      await this.updateRestaurant(restaurant)
      // this.menus = restaurant.menus
      // if (this.menus) this.selectMenu(this.menus[0])
    },
    async getActiveOrders() {
      const { data: orders } = await this.$http.get(
        // eslint-disable-next-line no-underscore-dangle
        `/orders/by-id/${this.user._id}?status=confirmed,ready`,
        {
          withCredentials: true,
        }
      )
      this.activeOrders = orders
    },
    async getMenus() {
      try {
        const { data: response } = await this.$http.get(
          `/restaurants/${this.$route.query.id}/menus`,
          {
            withCredentials: true,
          }
        )
        this.menus = response[0].menus || []
        if (this.menus) this.selectMenu(this.menus[0])
      } catch (e) {
        this.error = e.stack
      }
    },
    async getCategories() {
      const categoriesData = (
        await this.$http.get('/api/Menu/GetCategoryPublish?account_name=wild-wv', {
          withCredentials: true,
        })
      ).data.data
      this.categories = categoriesData.items
    },
  },
}
</script>

<style>
@media all and (min-width: 768px) {
  .restaurant-cover {
    height: 250px;
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%);
    border-radius: 10px;
  }
  .restaurant-cover img {
    border-radius: 10px;
  }
  /*noinspection ALL*/
  .restaurant-rounded-image {
    width: 90px;
    height: 90px;
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 50%;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 20%) 0 2px 8px;
  }
  .restaurant-name {
    margin: 45px 0 0 0;
    font-size: 42px;
  }
}

@media all and (max-width: 768px) {
  .restaurant-cover {
    height: 274px;
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%);
    margin-left: -17px;
    margin-right: -17px;
  }

  /*noinspection ALL*/
  .restaurant-rounded-image {
    width: 90px;
    height: 90px;
    position: absolute;
    top: 16px;
    right: 0;
    border-radius: 50%;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 20%) 0 2px 8px;
  }
  .restaurant-name {
    margin: 15px 0 1px 0;
    font-size: 36px;
  }
}
</style>
