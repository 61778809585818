<template>
  <b-modal
    v-show="item"
    id="custom-modal"
    ref="myModalRef"
    class="custom-modal"
    centered
    scrollable
    @hide="resetDialog"
  >
    <div slot="modal-header" class="d-flex p-0 w-100 item">
      <div
        v-if="item"
        class="item d-flex px-1 w-100"
        style="box-shadow: rgba(0, 0, 0, 0.2) 0 calc(1px) 15px; padding-top: 1.3rem; padding-bottom: 1.3rem"
      >
        <feather-icon icon="XIcon" size="25" class="text-dark" @click="$emit('close')" />
        <div
          class="d-inline ml-1 font-circular text-dark"
          style="font-size: 1.5rem; white-space: nowrap; text-overflow: ellipsis; width: 85%;
              overflow: hidden;-webkit-line-clamp: 1;"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div>
      <div v-if="item" class="restaurant mt-1 px-0 d-flex flex-column">
        <b-img
          class="py-1"
          style="width: 100%; height: 350px; object-fit: cover"
          :src="
            'https://omnimenuio.imgix.net/' +
              item.url +
              '?ixlib=vue-2.8.4&auto=format&fit=crop&crop=entropy&h=400&w=400&dpr=1&q=80'
          "
          round
          :alt="item.name + ' image'"
        />
      </div>
      <div v-if="item" class="modifiers-list m-1">
        <div
          v-for="modifierListItem in item.modifier_List"
          :key="modifierListItem.id"
          class="modifier"
        >
          <div v-if="modifierListItem.minSelect > 0" class="d-flex justify-content-between">
            <div>
              <div class="font-weight-bolder font-medium-2 text-dark">
                {{ modifierListItem.displayName }}
              </div>
              <div class="font-small-3 font-weight-bold">
                select upto {{ modifierListItem.maxSelect }}
              </div>
            </div>
            <b-button size="sm" variant="outline-danger">Required</b-button>
          </div>
          <div class="my-0">
            <b-form-group>
              <b-form-checkbox-group
                id="full-width-checkbox"
                v-model="selectedModifiers"
                name="selectedUsers"
              >
                <b-form-invalid-feedback
                  :state="
                    (state[modifierListItem.id] || 0) <= modifierListItem.maxSelect &&
                      (modifierListItem.minSelect <= 1 || state[modifierListItem.id] || 0) >=
                        modifierListItem.minSelect
                  "
                  class="font-medium-1"
                >
                  Please select less than equal to {{ modifierListItem.maxSelect }} and more
                  than equal to {{ modifierListItem.minSelect }}
                </b-form-invalid-feedback>
                <b-form-checkbox
                  v-for="(modifier, index) in modifierListItem.modifiers"
                  :key="index"
                  :value="modifier"
                  size="lg"
                  class="d-flex justify-content-between my-1"
                  switch
                >
                  <div class="d-flex justify-content-between font-weight-bolder">
                    <div class="text-dark font-medium-1 ml-1">
                      {{ modifier.name }}
                    </div>
                    <div class="text-dark font-medium-1 ml-1">
                      +${{ modifier.price.toFixed(2) }}
                    </div>
                  </div>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="px-1" style="margin-bottom: 150px">
        <div class="text-dark font-weight-bolder font-medium-2 mb-1">
          Preferences
        </div>
        <label for="textarea-statse2" class="font-medium-1 font-weight-bold"
          >Special Instructions
        </label>
        <b-form-textarea
          id="textarea-statse2"
          v-model="specialInstruction"
          class="font-weight-bolder font-medium-1 text-dark"
          style="border-width: 2px;"
          placeholder="Add any special requests (e.g., food allergies, extra spicy, etc.) and the store will do its best to accommodate you."
          rows="4"
        />
      </div>
    </div>

    <div slot="modal-footer">
      <div
        v-if="item"
        class="item d-flex justify-content-around align-items-center px-1 py-1 position-absolute bg-white"
        style="box-shadow: rgba(0, 0, 0, 0.2) 0 calc(-1px) 15px; bottom: 0; left: 0; right: 0;"
      >
        <b-form-spinbutton
          id="cart-quantity"
          v-model="count"
          inline
          class="text-dark"
          min="1"
        />
        <b-button
          class="round-false text-nowrap font-medium-2"
          variant="dark"
          @click="addToCart()"
        >
          Add to cart - ${{ price.toFixed(2) }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<style scoped>
.b-form-spinbutton {
  color: black;
  outline: none;
  border: none;
}
</style>

<script>
import {
  BImg,
  BModal,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { mapMutations, mapState, mapActions } from 'vuex'
// import store from '@/store'

export default {
  components: {
    BImg,
    BModal,
    BButton,
    BFormSpinbutton,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormTextarea,
    BFormInvalidFeedback,
    // BNavItemDropdown,
    // BDropdownItem,
    // BDropdownDivider,
    // BAvatar,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    restaurant: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedModifiers: [],
      count: 1,
      specialInstruction: '',
      items: [],
    }
  },
  computed: {
    ...mapState({
      cartRestaurant: state => state.restaurant.cartRestaurant,
      cart: state => state.cart.added,
      cartCount: state =>
        state.cart.added.reduce(
          (item, current) => ({ quantity: item.quantity + current.quantity }),
          { quantity: 0 }
        ).quantity,
      user: state => state.user.user,
      showLogin: state => state.user.showLogin,
    }),
    state() {
      const state = {}
      this.selectedModifiers.map(modifier => {
        if (state[modifier.modifyListId]) state[modifier.modifyListId] += 1
        else state[modifier.modifyListId] = 1
        return false
      })
      return state
    },
    price() {
      let modifiersPrice = { price: 0 }
      if (this.selectedModifiers.length > 0) {
        modifiersPrice = this.selectedModifiers.reduce((modifier, current) => ({
          price: current.price + modifier.price,
        }))
      }
      // console.log('selected', this.selectedModifiers)
      return this.count * (this.item.price + modifiersPrice.price)
    },
  },
  watch: {
    item(newVal) {
      if (newVal) {
        this.$refs.myModalRef.show()
      } else {
        this.$refs.myModalRef.hide()
      }
    },
  },
  methods: {
    ...mapMutations({
      updateUser: 'user/UPDATE_USER',
      toggleLogin: 'user/toggleLogin',
    }),
    ...mapActions({
      addItemToCart: 'cart/addItemToCart',
      clearCart: 'cart/clearCart',
      updateCartRestaurant: 'restaurant/updatecartRestaurant',
    }),
    async addToCart() {
      const add = async () => {
        const modifiers = []
        this.selectedModifiers.map(selectedModifier => {
          this.item.modifier_List.find(modifierListItem => {
            if (!modifierListItem.modifiers) return false
            const x = modifierListItem.modifiers.some(
              modifier => modifier.id === selectedModifier.id
            )
            if (x) {
              const index = modifiers.findIndex(
                modifier => modifier.id === modifierListItem.id
              )
              if (index === -1) {
                // eslint-disable-next-line no-unused-expressions,no-param-reassign
                const modifierToPush = {
                  id: modifierListItem.id,
                  name: modifierListItem.displayName,
                  modifiers: [selectedModifier],
                }
                modifiers.push(modifierToPush)
              } else {
                modifiers[index].modifiers.push(selectedModifier)
              }
            }
            return x
          })
          return undefined
        })

        delete this.restaurant.menus
        await this.updateCartRestaurant(this.restaurant)
        // eslint-disable-next-line no-underscore-dangle
        localStorage.setItem('restaurantID', this.restaurant._id)
        const item = {
          cartItemId: this.item.itemId + Date.now() + this.price,
          id: this.item.itemId,
          discount: 0,
          name: this.item.name,
          url: this.item.url,
          price: this.item.price,
          totalPrice: this.price,
          quantity: this.count,
          specialInstruction: this.specialInstruction,
          categoryID: this.item.categoryID,
          modifiers,
        }
        // console.log('cart', cart)
        await this.addItemToCart(item)
        this.$emit('close')
      }

      if (
        this.cartRestaurant &&
        // eslint-disable-next-line no-underscore-dangle
        this.cartRestaurant._id !== String(this.restaurant._id) &&
        this.cartCount > 0
      ) {
        this.$bvModal
          .msgBoxConfirm(
            "There are already some items from another restaurant. You can't add items from\n" +
              '        multiple restaurants.',
            {
              title: 'Clear old cart and proceed?',
              size: 'sm',
              okVariant: 'primary',
              okTitle: 'Proceed',
              cancelTitle: 'Cancel',
              cancelVariant: 'outline-dark',
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then(async value => {
            if (value) {
              this.clearCart()
              await add()
            }
          })
      } else {
        await add()
      }
    },
    resetDialog() {
      this.$emit('close')
      this.count = 1
      this.selectedModifiers = []
      this.specialInstruction = ''
    },
  },
}
</script>
