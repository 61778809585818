<template>
  <div>
    <b-row class=" " style="">
      <b-col
        v-for="item of items"
        :key="item.id + item.name"
        lg="4"
        md="6"
        sm="12"
        @click="$emit('selectItem', { ...item })"
      >
        <div
          class="restaurant my-1 px-0 position-relative overflow-auto w-100"
          style="background: #eeeeeeff;min-height: 154px;cursor: pointer"
        >
          <div
            class="float-left bg-white"
            style="width: 50%; max-height: 150px; max-width: 150px; min-width: 100px;min-height: 100px; margin: 2px"
          >
            <b-img-lazy
              :src="
                'https://omnimenuio.imgix.net/' +
                  item.url +
                  '?ixlib=vue-2.8.4&auto=format&fit=crop&crop=entropy&h=140&w=140&dpr=1&q=75'
              "
              round
              class="w-100 h-100"
              style=""
              :alt="item.name + ' image'"
            />
          </div>
          <div
            class="restaurant-details d-flex flex-column justify-content-between h-100"
            style="width: auto; padding: 3px"
          >
            <div class="badges overflow-auto mx-1 d-flex">
              <b-badge
                v-for="badge in item.badges"
                :key="badge"
                pill
                :variant="badges[badge].variant"
                class=" text-capitalize font-small-1"
                style="margin: 7px 5px"
              >
                {{ badges[badge].title }}
              </b-badge>
            </div>

            <div
              class=" text-dark font-medium-4 mx-1 font-circular"
              style="margin-top: 6px;margin-bottom: 3px;max-height: 68px; overflow: hidden;
           text-overflow: ellipsis; -webkit-line-clamp: 3; display: -webkit-box; -webkit-box-orient: vertical;"
            >
              {{ item.name }}
            </div>
            <div
              class="font-weight-bold font-medium-1 mx-1 font-circular-book"
              style=" max-height: 35px; overflow: hidden;
           text-overflow: ellipsis; -webkit-line-clamp: 2; display: -webkit-box; -webkit-box-orient: vertical;"
            >
              {{ item.description }}
            </div>
            <div class="text-dark font-medium-1 font-weight-bold mx-1 mt-1">
              ${{ item.price.toFixed(2) }}
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.items.card {
  border-radius: 2rem;
}
.items > .card-body {
  padding: 10px 0;
}
.items > .card-body .card-body {
  padding: 0;
}
</style>

<script>
import { BImgLazy, BBadge, BCol, BRow } from 'bootstrap-vue'

export default {
  components: {
    BImgLazy,
    BBadge,
    BCol,
    BRow,
    // BNavItemDropdown,
    // BDropdownItem,
    // BDropdownDivider,
    // BAvatar,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    restaurant: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      badges: {
        popular: {
          title: 'POPULAR',
          variant: 'primary',
        },
        fast: {
          title: 'FAST DELIVERY',
          variant: 'primary',
        },
        free: {
          title: 'FREE DELIVERY',
          variant: 'primary',
        },
      },
    }
  },
}
</script>
